import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Checkout from "../components/stripe-checkout"

class ThanksPage extends React.Component
{
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title
        const menu = data.site.siteMetadata.menuLinks
        const tagline = data.site.siteMetadata.tagline
    
        return (
          <Layout location={this.props.location} title={siteTitle} menuLinks={menu} tagline={tagline}>
            <SEO title="Teach, don't speak workshop - Thank you!" />
            <h1>You have registered!</h1>

            
            
      
            <p>Thank you so much for registering for the "Teach, don't speak" workshop! Look for an email from me within the next 36 hours giving you all the details on the final schedule and how to connect for the workshop!</p>



          </Layout>
        )
      }

}

export default ThanksPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        tagline
        menuLinks {
          name
          link
        }
      }
    }
  }
`